<template>
	<div class="container">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'roles-index'}">Roles</router-link>
				</li>
			</ol>
		</nav>

		<vue-good-table
				:is-loading="loading"
				:columns="columns"
				:rows="models" class="mb-3" @on-row-click="openUserDetails">
			<template slot="table-row" slot-scope="props">
				<div class="d-flex align-items-center" v-if="props.column.field == 'Agents'">
					<template v-if="props.row.Agents.length > 0">
						<router-link tag="span" @click.prevent.stop class="cursor-pointer"
									 :to="{name: 'users-edit-user_id', params: { user_id: agent.Id } }"
									 v-for="agent in props.row.Agents" :key="agent.Id">
							<agent-card :compact="true" :agent="agent"></agent-card>
						</router-link>
					</template>
					<button class="btn text-danger" @click.prevent.stop="deleteRole(props.row.Id)" v-else>
						<i class="fa fa-trash"></i>
					</button>
				</div>
				<span v-else-if="props.column.field == 'Description'">
					<div class="p-1 bg-light" v-if="props.formattedRow[props.column.field]">
						{{props.formattedRow[props.column.field]}}
					</div>
					<div>
						<div v-for="(operation, permission) in props.row.Permissions" :title="operation.toUpperCase()"
							 v-if="['write', 'read'].includes(operation)" v-text="`${permission}: ${operation}`"
							 class="badge text-capitalize rounded mr-2"
							 :class="{'badge-primary': operation === 'write', 'badge-success': operation === 'read'}"></div>
					</div>
				</span>
				<span v-else>
				  {{props.formattedRow[props.column.field]}}
				</span>
			</template>
		</vue-good-table>

		<router-link :to="{name: 'roles-new'}" class="btn btn-primary" v-if="!loading && canWrite('roles')">
			<i class="fa fa-plus mr-2"></i>
			Add role
		</router-link>

	</div>
</template>

<script>
	import api from '@/api'
	import AgentCard from "../../components/agents/agent-card";

	export default {
		components: {AgentCard},
		data() {
			return {
				models: [],
				loading: false,
				columns: [
					{
						label: 'Name',
						field: 'Name',
					},
					{
						label: 'Description',
						field: 'Description',
					},
					{
						label: 'Users',
						field: 'Agents',
					},
				],
			}
		},
		mounted() {
			this.refreshRoles()
		},
		methods: {
			openUserDetails(e) {
				this.$router.push({
					name: "roles-edit-id",
					params: {
						id: e.row.Id
					}
				})
			},
			async deleteRole(role_id) {
				await api.Roles.delete(role_id)
				this.refreshRoles()
			},
			async refreshRoles() {
				this.loading = true
				this.models = await api.Roles.getList().finally(() => this.loading = false)
			}
		}
	}
</script>
